import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
// import "./scss/style.scss";
import { Provider } from "react-redux";
import createStore from "./store/store";
import reportWebVitals from "./reportWebVitals";

const store = createStore;

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);
reportWebVitals();
//serviceWorker.unregister();
