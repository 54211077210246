import React, { useState, Fragment, useEffect } from "react";
import loader from './loader.css';

const mystyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	height: "100vh",
	width: "100%",
};

const Loader = () => {
	const [loaderRandomKey, setLoaderRandomKey] = useState(1);

	useEffect(() => {
		setLoaderRandomKey(Math.floor(Math.random() * 11));
	}, []);
	return (
		<Fragment>
			<div className="d-nones">
				<div className="loaderWrapperBox" style={mystyle}>
					<div className="loaderInner text-center">
					<svg class="custompreloader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256.35 362.48"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M62.64,108.26Q48,139.89,48.05,164.82q0,51.39,35.27,63a82.45,82.45,0,0,0,21.14,2.74,89.64,89.64,0,0,0,21.74-2.74,129.9,129.9,0,0,0,39.23-17.33q17.32-11.56,21.89-22.2Q199.49,160,213.48,160q7,0,9.42,7.9a12.24,12.24,0,0,1,.61,6.69,174.17,174.17,0,0,1-13.07,47q-10,23.26-28.29,53.37,36.49.3,66.6,7.91,6.39,1.51,6.39,4.26,0,3.35-8.82,1.21-21-5.47-60.52-5.47h-7.9q-18.56,26.76-49.42,47.29T68.42,358.23q-16.12,4.25-27.37,4.25T22.2,359.75Q0,351.53,0,338.16a20.24,20.24,0,0,1,2.13-8.52q8.21-18.24,43.18-33.75a209.1,209.1,0,0,1,55.8-16.12A522.62,522.62,0,0,1,167,274.9q22.5-38,30.71-74.8l1.22-5.17a176.41,176.41,0,0,1-33,24.78q-18.39,10.8-32.08,15-21.89,7-36,7T73,237.8Q53.52,231.13,41.36,211q-13.08-21.27-13.08-50.78T43.79,97Q23.72,76.32,23.72,53.06T42.57,8.82C45,6.19,46.78,5.22,47.9,5.93s.65,2.49-1.37,5.32q-13.7,18.26-13.69,36.8,0,21.29,15.82,39.83A125.93,125.93,0,0,1,55.35,76a157.64,157.64,0,0,1,64.46-59.6Q151.75,0,181.55,0A112.13,112.13,0,0,1,198,1.22q39.22,6.07,52.91,32.23a47.07,47.07,0,0,1,5.47,22.35q0,12-7.6,25.7-17,29.49-59.6,41.36a167.73,167.73,0,0,1-45.92,6.38Q94,129.24,62.64,108.26Zm-47.13,232q0,8.52,14.6,11.56a43.84,43.84,0,0,0,10.64,1.22q21.59,0,54.74-15.51,42-19.16,66.59-54.44-31.92.92-56,4.87-34.66,5.16-66,25.24Q15.51,329,15.51,340.28Zm173-330.85q-26.16,0-55.35,17.63Q90.32,52.61,66.6,100.35a87.61,87.61,0,0,0,33,15.51q18.7,4.56,30,4.56,64.15,0,97-34.36Q243,68.42,243,50.78q0-28-31.32-37.7A76.2,76.2,0,0,0,188.54,9.43Z"/></g></g></svg>
					</div>
				</div>
			</div>
		</Fragment>

	);
};
export default Loader;

