import { MODAL_CLOSE, MODAL_OPEN, MODAL_STEP, MODAL_PREVIEW } from "./contant";
import { createReducer } from "../../utils/reducerUtil";

const initialState = null;

/**
 *
 * @param state
 * @param paylaod
 * @returns {{modalType: *, modalProps: *}}
 */
export const openModal = (state, paylaod) => {
	const { modalType, modalProps } = paylaod;
	return { modalType, modalProps, step: "first", preview: false };
};

/**
 *
 * @param state
 * @param paylaod
 * @returns {null}
 */
export const closeModal = (state, paylaod) => {
	return null;
};

/**
 *
 * @param state
 * @param paylaod
 * @returns {null}
 */
export const stepModal = (state, payload) => {
	return {
		...state,
		step: payload,
	};
};

/**
 *
 * @param state
 * @param paylaod
 * @returns {null}
 */
export const previewModal = (state, payload) => {
	return {
		...state,
		preview: payload,
	};
};

export default createReducer(initialState, {
	[MODAL_OPEN]: openModal,
	[MODAL_CLOSE]: closeModal,
	[MODAL_STEP]: stepModal,
	[MODAL_PREVIEW]: previewModal,
});
