import { combineReducers } from "redux";

import auth from "./auth/reducer";
import modals from "./global_modal/reducer";

const rootReducer= combineReducers({
	auth,
	modals,
});

export default rootReducer