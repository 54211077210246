import React, { useEffect, useState } from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend as Backend } from "react-dnd-html5-backend";
import ProtectedRoute from "./routing/ProtectedRoute";
import PublicRoute from "./routing/PublicRoute";
import Loader from "./components/Loader";
import SnackbarProvider from "react-simple-snackbar";
import 'react-toastify/dist/ReactToastify.css';


/**
 * @ { common routes }
 * @type {React.LazyExoticComponent<React.ComponentType<any>>}
 */
 const Demo = React.lazy(() => import("./pages/PublicPages/Demo/index")); 
 const Layout = React.lazy(() => import("./containers/Layouts/TheLayout"));
const Home = React.lazy(() => import("./pages/PublicPages/Home"));
const SearchListing = React.lazy(() => import("./pages/PublicPages/searchlisting"));
const SearchDetail = React.lazy(() => import("./pages/PublicPages/searchdetail"));
const BlogListing = React.lazy(() => import("./pages/PublicPages/bloglisting"));
const BlogDetail = React.lazy(() => import("./pages/PublicPages/blogdetail"));
const About = React.lazy(() => import("./pages/PublicPages/about")); 
const Terms = React.lazy(() => import("./pages/PublicPages/terms"));
const Privacy = React.lazy(() => import("./pages/PublicPages/privacy"));
const Contact = React.lazy(() => import("./pages/PublicPages/contact"));
const HomeBusiness = React.lazy(() => import("./pages/BusinessInterface/home"));
const Features = React.lazy(() => import("./pages/BusinessInterface/features"));
const Pricing = React.lazy(() => import("./pages/BusinessInterface/pricing"));
const FAQ = React.lazy(() => import("./pages/BusinessInterface/faq"));
const AboutBI = React.lazy(() => import("./pages/BusinessInterface/about"));
const BlogListingBusinessInterface = React.lazy(() => import("./pages/BusinessInterface/bloglisting"));
const blogdetailBusinessInterface = React.lazy(() => import("./pages/BusinessInterface/blogdetail"));
const BusinessContact = React.lazy(() => import("./pages/BusinessInterface/contact"));
const Giftcard = React.lazy(() => import("./pages/customer/giftcard"));
const MySalon = React.lazy(() => import("./pages/Owner/mysalon"));
const MyStaff = React.lazy(() => import("./pages/Owner/mystaff")); 
const MyStaffDetail = React.lazy(() => import("./pages/Owner/mystaffdetail")); 
const Booking = React.lazy(() => import("./pages/Owner/booking")); 
const portfolio = React.lazy(() => import("./pages/Owner/portfolio")); 
const Registration = React.lazy(() => import("./pages/registration/step1")); 
const RegistrationStep2 = React.lazy(() => import("./pages/registration/step2")); 
const RegistrationStep3 = React.lazy(() => import("./pages/registration/step3")); 
const RegistrationStep4 = React.lazy(() => import("./pages/registration/step4")); 


const App = () => {
	/**
	 * @ { React hooks }
	 */
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);

	return (
		<DndProvider backend={Backend}>
			<BrowserRouter>
				<SnackbarProvider>
					<React.Suspense fallback={<Loader />}>  
						<Switch>
							<PublicRoute exact path="/" component={Home} />
							<PublicRoute exact path="/demo" component={Demo} />
							<PublicRoute exact path="/searchlisting" component={SearchListing} />
							<PublicRoute exact path="/searchdetail" component={SearchDetail} />
							<PublicRoute exact path="/bloglisting" component={BlogListing} />
							<PublicRoute exact path="/blogdetail" component={BlogDetail} />
							<PublicRoute exact path="/about" component={About} />
							<PublicRoute exact path="/terms" component={Terms} />
							<PublicRoute exact path="/privacy" component={Privacy} />
							<PublicRoute exact path="/contact" component={Contact} /> 
							<PublicRoute exact path="/businessinterface/home" component={HomeBusiness} />
							<PublicRoute exact path="/businessinterface/features" component={Features} />
							<PublicRoute exact path="/businessinterface/pricing" component={Pricing} />
							<PublicRoute exact path="/businessinterface/faq" component={FAQ} />
							<PublicRoute exact path="/businessinterface/about" component={AboutBI} />
							<PublicRoute exact path="/businessinterface/bloglisting" component={BlogListingBusinessInterface} />
							<PublicRoute exact path="/businessinterface/blogdetail" component={blogdetailBusinessInterface} />
							<PublicRoute exact path="/businessinterface/contact" component={BusinessContact} />
							<PublicRoute exact path="/customer/giftcard" component={Giftcard} />
							<PublicRoute exact path="/Owner/mysalon" component={MySalon} /> 
							<PublicRoute exact path="/Owner/mystaff" component={MyStaff} />
							<PublicRoute exact path="/Owner/mystaffdetail" component={MyStaffDetail} />
							<PublicRoute exact path="/Owner/booking" component={Booking} />
							<PublicRoute exact path="/Owner/portfolio" component={portfolio} />
							<PublicRoute exact path="/registration/step1" component={Registration} />
							<PublicRoute exact path="/registration/step2" component={RegistrationStep2} />
							<PublicRoute exact path="/registration/step3" component={RegistrationStep3} />
							<PublicRoute exact path="/registration/step4" component={RegistrationStep4} />
							<ProtectedRoute
								account_restrict={true}  
								path="/dashboard"
								component={Layout}
							/>
							<Route path="**" component={NoPageFound} />
						</Switch>
					</React.Suspense>
				</SnackbarProvider>
			</BrowserRouter>
		</DndProvider>
	);
};

/**
 * @returns {JSX.Element}
 * @constructor
 */
const NoPageFound = () => {
	return <h1>404 Page not found</h1>;
};

export default App;
