import { AUTH_SPINNING ,LOGIN_SUCCESS } from "./types";

const initialState = {
	token: null,
	isAuthenticated: null,
	loading: false,
	authData : {}
};

export default (state = initialState, action) => {
	 //const { type, payload } = action;
	switch (action.type) {
		case AUTH_SPINNING:
			return {
				...state,
				loading: action.isLoading,
			};
			case LOGIN_SUCCESS:
				return {
					...state,
					authData: action.auth,
					token : action.token,
					
				};
		default:
			return state;
	}
};
