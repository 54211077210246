import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({
	component: Component,
	account_restrict,
	...rest
}) => {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? (
					<Redirect to="/dashboard" />
				) : (
					<Redirect location="/dashboard" to="/dashboard" />
				)
			}
		/>
	);
};

export default ProtectedRoute;
